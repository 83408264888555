$(document).ready(function(){
    $('.owl-carousel').owlCarousel({
        animateOut: 'fadeOut',
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        dots: false, 
        items: 1,
        loop: true
    })
});
$(document).ready(function() {
    // GLOBAL VARS
    let windowWidth = window.innerWidth;
    let isDesktop;
    let menuStage = 0;
    const setBreakpoint = 992;

    // STICKY HEADER VARS
    const headerDiv = $('.header');
    const navBar = $('.navigation-container');

    // MOBILE NAV ELEMENTS
    const navBtn = $('.nav-btn');
    const menuOpen = $('.nav-btn--burger');
    const menuClose = $('.nav-btn--close');
    const navMenu = $('#navMenu');

    // MEGAMENU ELEMENTS
    let activeMenu;
    let activeTertiaryMenu;
    let controllerMenu;

    const desktopPrimaryLink = '.nav-links > li[aria-haspopup="true"]';
    const desktopSecondaryLink = '.mega-menu__link--secondary[aria-haspopup="true"]';
    const mobilePrimaryLink = '.nav-links > li[aria-haspopup="true"] > a';
    const mobileSecondaryLink = '.mega-menu__link--secondary > a';
    const backButton = '.mega-menu__link--back';

    function resetMenu() {
        $('.mega-menu').removeClass('mega-menu--flex').removeClass('mega-menu--block').removeAttr("style").attr('aria-hidden', 'true');
        $('.mega-menu__tertiary').removeClass('mega-menu--block').removeClass('mega-menu--flex').removeAttr("style").attr('aria-hidden', 'true');
        $(navMenu).removeClass('expanded');
        $(navBtn).attr('aria-expanded', 'false');
        $(desktopPrimaryLink).attr('aria-expanded', 'false');
        $(desktopSecondaryLink).attr('aria-expanded', 'false');

        menuStage = 0;
        // console.log("Reset run - mega-menu--flex and mega-menu--block classes removed");
    }

    // STICKY HEADER BEHAVIOUR
    function stickyHeader() {

        // console.log("Sticky header check: isDesktop = "+isDesktop);
        if (!isDesktop) {
            headerDiv.removeClass('stick-top');

            $(window).on('scroll', function() {
                let scrollTop = $(window).scrollTop(); // Current scroll position of the window
                let navOffsetTop = navBar.offset().top; // The offset of the div from the top of the document

                if (scrollTop > navOffsetTop) {
                    navBar.addClass('stick-top navigation-container--border-bottom');
                } else if (scrollTop <= 50) {
                    navBar.removeClass('stick-top navigation-container--border-bottom');
                }
            });
        } else {
            headerDiv.addClass('stick-top');
            navBar.removeClass('stick-top navigation-container--border-bottom');
        }
    }

    // DISABLE PRIMARY AND SECONDARY NAV LINKS FOR MOBILE
    function disableNavLinks() {
        $(mobilePrimaryLink + ', ' + mobileSecondaryLink).click(function(event) {
            if (!isDesktop) {
                event.preventDefault();
                // console.log("Click disabled");
            }
        });
    }

    function checkWindowSize() {
        windowWidth = window.innerWidth;
        // console.log("WIndow size check: "+windowWidth);

        if (windowWidth >= setBreakpoint) {
            isDesktop = true;
            // console.log("WIndow size check: Desktop");
        } else if (windowWidth < setBreakpoint) {
            isDesktop = false;
            // console.log("WIndow size check: Mobile");
        }
        // console.log("WIndow size check: isDesktop = "+isDesktop);
        resetMenu();
        stickyHeader();
        disableNavLinks();
    }

    // MOBILE NAV BUTTONS
    $(menuOpen).click(function() {
        $(navMenu).addClass('expanded');
        $(navBtn).attr('aria-expanded', 'true');
    });
    $(menuClose).click(function() {
        $(navMenu).removeClass('expanded');
        resetMenu();    
        // console.log("Menu stage = "+menuStage);
    });

    let closePrimaryMenuTimeout;

    function megaMenuReveal() {
        // DESKTOP HOVER FUNCTIONALITY    
        $(desktopPrimaryLink).hover(
            function() {
                if (isDesktop) {
                    clearTimeout(closePrimaryMenuTimeout);

                    // Close any previously open menu immediately
                    $(desktopPrimaryLink).attr('aria-expanded', 'false')
                        .find('.mega-menu')
                        .removeClass('mega-menu--flex')
                        .attr('aria-hidden', 'true')
                        .stop()
                        .css('opacity', 0);

                    activeMenu = $(this).find('.mega-menu');

                    // On hover, fade in the dropdown and update ARIA attributes
                    $(this).attr('aria-expanded', 'true');

                    $(activeMenu).attr('aria-hidden', 'false');
                    $(activeMenu).addClass('mega-menu--flex')
                        .outerWidth(); 

                    $(activeMenu).animate(
                        { opacity: 1 },
                            150, function() {
                            // console.log("Hover on animation complete");
                        }
                    );
                }
            },
            function() {
                if (isDesktop) {
                    activeMenu = $(this).find('.mega-menu');
                    let self = this;

                    // Set a timeout to close the menu after 200ms
                    closePrimaryMenuTimeout = setTimeout(function () {
                        $(self).attr('aria-expanded', 'false');
                        $(activeMenu).attr('aria-hidden', 'true');
                        $(activeMenu).stop().animate({ opacity: 0 }, 150, function () {
                            $(activeMenu).removeClass('mega-menu--flex');
                        });
                    }, 350);
                }
            }
        );

        // DESKTOP SECONDARY HOVER
        $(desktopSecondaryLink).hover(
            function() {
                if (isDesktop) {
                    activeTertiaryMenu = $(this).find('.mega-menu__tertiary');

                    $(this).attr('aria-expanded', 'true');
                    $(activeTertiaryMenu).attr('aria-hidden', 'false');
                }
            },
            function() {
                if (isDesktop) {
                    let activeTertiaryMenu = $(this).find('.mega-menu__tertiary');

                    $(this).attr('aria-expanded', 'false');
                    $(activeTertiaryMenu).attr('aria-hidden', 'true');
                }
            }
        );

        // MOBILE PRIMARY NAV FUNCTIONALITY
        $(document).on('touchstart', mobilePrimaryLink, function(event) {

            let touchStartTime = new Date().getTime();
            
            $(this).on('touchend', function() {
                let touchEndTime = new Date().getTime();
                if (touchEndTime - touchStartTime < 250) {  // If touch was short enough

                    if (event.target == this) {
                        if (!isDesktop) {
                            // console.log("mobile "+"windowWidth = " + windowWidth);

                            if (menuStage < 1) {menuStage++};

                            controllerMenu = $(this).closest('li[aria-haspopup="true"]');
                            activeMenu = $(this).siblings('.mega-menu');
                            
                            $(controllerMenu).attr('aria-expanded', 'true');
                            $(activeMenu).attr('aria-hidden', 'false');

                            $(activeMenu).addClass('mega-menu--block')
                            .outerWidth(); // Reflow

                            $(activeMenu).animate(
                                { left: 0 },
                                150, function() {
                                    // console.log("animation complete");
                            });
                            // console.log("Menu stage = "+menuStage);
                        }
                    }
                }
            });
        });

        // MOBILE SECONDARY NAV FUNCTIONALITY
        $(document).on('touchstart', mobileSecondaryLink, function(event) {

            let touchStartTime = new Date().getTime();
            
            $(this).on('touchend', function() {
                let touchEndTime = new Date().getTime();
                if (touchEndTime - touchStartTime < 250) {  // If touch was short enough

                    if (event.target == this) {
                        if (!isDesktop) {
                            // console.log("secondary link tapped");

                            if (menuStage < 2) {menuStage++};

                            controllerMenu = $(this).closest('.mega-menu__link--secondary');
                            activeMenu = $(this).siblings('.mega-menu__tertiary');
                            
                            $(controllerMenu).attr('aria-expanded', 'true');
                            $(activeMenu).attr('aria-hidden', 'false');
                            
                            $(activeMenu).addClass('mega-menu--block')
                            .outerWidth(); // Reflow

                            $(activeMenu).animate(
                                { left: 0 },
                                150, function() {

                            });
                            // console.log("Menu stage = "+menuStage);
                        }
                    }
                }
            });
        });

        // BACK BUTTON FUNCTIONALITY
        $(document).on('touchstart', backButton, function(event) {

            let touchStartTime = new Date().getTime();
            
            $(this).on('touchend', function() {
                let touchEndTime = new Date().getTime();
                if (touchEndTime - touchStartTime < 250) {  // If touch was short enough

                    if (event.target == this) {
                        if (!isDesktop) {
                            // console.log("Back button tapped");

                            if (menuStage === 1) {
                                activeMenu = $(this).closest('.mega-menu');
                                controllerMenu = $(this).closest('li[aria-haspopup="true"]');

                                menuStage--
                                // console.log("Menu stage = "+menuStage);

                                $(activeMenu).animate(
                                    { left: 305 },
                                    150, function() {
                                            
                                        function removeSecondaryMenu() {
                                            $(activeMenu).removeClass('mega-menu--block').attr('aria-hidden', 'true');
                                            $(controllerMenu).attr('aria-expanded', 'false');
                                        }

                                        setTimeout(removeSecondaryMenu, 150);

                                        // TODO - WHY DO I NEED TO PUT THE BLOCK BELOW BEHIND A TIMEOUT???
                                        /*$(activeMenu).removeClass('mega-menu--block').attr('aria-hidden', 'true');
                                        $(controllerMenu).attr('aria-expanded', 'false');;*/
                                });
                            } else if (menuStage === 2) {
                                activeMenu = $(this).closest('.mega-menu__tertiary');
                                controllerMenu = $(this).closest('.mega-menu__link--secondary');
                                
                                $(activeMenu).animate(
                                    { left: 305 },
                                    150, function() {
                                        $(activeMenu).removeClass('mega-menu--block').attr('aria-hidden', 'true');
                                        $(controllerMenu).attr('aria-expanded', 'false');
                                });

                                menuStage--
                                // console.log("Menu stage = "+menuStage);
                            };
                        }
                    }
                }
            });
        });
    }

    checkWindowSize();
    megaMenuReveal();
    $(window).resize(checkWindowSize);
});